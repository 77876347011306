<template>
  <div class="user-not-logged">
    <BaseButton class="btn--login" @click="onClickAuthor(MODAL_TYPES.LOGIN, 'login')">Đăng Nhập</BaseButton>
    <BaseButton class="btn--register" @click="onClickAuthor(MODAL_TYPES.LOGIN, 'register')">Đăng Ký</BaseButton>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '~/components/common/base-button.vue'
import useHeader from '~/composables/useHeader'

const { onClickAuthor, MODAL_TYPES } = useHeader()
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/layouts/header/user-not-login-block.scss"></style>
