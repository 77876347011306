import { defineStore, acceptHMRUpdate } from 'pinia'

interface PrevCategoryActive {
  indexList: number[];
  classNameList: string;
  classNameItem: string;
}

interface PromotionState {
  prevCategoryActive: PrevCategoryActive;
}

interface MutatePayload {
  property: keyof PromotionState;
  with: any;
}

interface SetPrevCategoryActivePayload {
  indexList: number[];
  classNameList: string;
  classNameItem: string;
}

export const usePromotionStore = defineStore('promotionStore', {
  state: (): PromotionState => ({
    prevCategoryActive: {
      indexList: [],
      classNameList: '',
      classNameItem: ''
    }
  }),
  actions: {
    mutate(payload: MutatePayload) {
      this.$state[payload.property] = payload.with
    },
    setPrevCategoryActive(payload: SetPrevCategoryActivePayload) {
      this.prevCategoryActive = payload
    }
  },
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePromotionStore, import.meta.hot))
}
