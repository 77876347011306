<template>
  <div class="user-logged">
    <div class="user-logged__btn-deposit" @click="openMenuDeposit()">
      <span class="label-number">{{ $formatNumberWithCommas(userAmount, ',') }}</span>
      <BaseImg class="icon-deposit-plus" src="/assets/images/unit.png" />
      <span class="label-text">Nạp</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import useHeaderLogin from '~/composables/useHeader'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { useAppStore } from '~/store/app'
import BaseImg from '~/components/common/base-img.vue'
const { $pinia } = useNuxtApp()
const appstore = useAppStore($pinia)
const { setDepositFrom } = appstore

const { userAmount } = useHeaderLogin()

const { toggleMenuDeposit } = useToggleMenu()

const openMenuDeposit = () => {
  setDepositFrom('home')
  toggleMenuDeposit()
}
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/layouts/header/user-login-block.scss"></style>
